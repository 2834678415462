import { ReactChild, useEffect, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { isBrowser } from 'react-device-detect';

import ARTSY from '../../static/images/artsy.svg';
import FACEBOOK from '../../static/images/facebook.svg';
import INSTAGRAM from '../../static/images/instagram.svg';
import MENU from '../../static/images/menu.svg';

import Artwork from '../artwork';
import { useArtwork } from '../../context/Artwork';
import { usePublications } from '../../context/Publications';
import PublicationsModal from '../publications_modal';

type NavigationProps = {
  children: ReactChild | ReactChild[];
};

export default function Navigation({ children }: NavigationProps) {
  const history = useHistory();
  const location = useLocation();

  const artist =
    location.pathname.includes('/artist') && location.pathname.length > 7;

  const exhibition =
    location.pathname.includes('/exhibition') &&
    !(
      location.pathname === '/exhibition-upcoming' ||
      location.pathname === '/exhibition-past'
    );

  const onlineExhibition = location.pathname.includes('/online-exhibition');

  const main = location.pathname === '/';

  const { artwork } = useArtwork();

  const { publications } = usePublications();

  const [isTop, setIsTop] = useState(false);

  const [isMenu, setIsMenu] = useState(false);

  const handleLogo = () => {
    if (location.pathname === '/') {
      return 'LEE & BAE';
    } else if (location.pathname.includes('/artist')) {
      return 'ARTIST';
    } else if (location.pathname.includes('/exhibition')) {
      return 'EXHIBITION';
    } else if (location.pathname.includes('/online-exhibition')) {
      return 'ONLINE EXHIBITION';
    } else if (location.pathname.includes('/artfair')) {
      return 'ART FAIR';
    } else if (location.pathname.includes('/publications')) {
      return 'PUBLICATIONS';
    } else if (location.pathname.includes('/about')) {
      return 'ABOUT';
    }
  };

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsTop(false);
    } else {
      setIsTop(true);
    }
  };

  useEffect(() => {
    if (publications || artwork || isMenu) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [publications, artwork, isMenu]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {isBrowser ? (
        <Box
          style={{
            backgroundColor: location.pathname.includes('/online-exhibition')
              ? '#F5F2EB'
              : '',
          }}
        >
          <div
            className={cn('header')}
            style={{
              backgroundColor:
                onlineExhibition && isTop
                  ? '#F5F2EB'
                  : isTop
                  ? '#fff'
                  : 'transparent',
              borderBottom: isTop ? '1px solid #ddd' : '1px solid transparent',
            }}
          >
            <span
              className={cn('header-page')}
              onClick={() => history.push('/artist')}
              style={{
                color: (artist || main || exhibition) && !isTop ? '#fff' : '',
              }}
            >
              ARTIST
            </span>

            <span
              className={cn('header-page')}
              onClick={() => history.push('/exhibition/onview')}
              style={{
                color: (artist || main || exhibition) && !isTop ? '#fff' : '',
              }}
            >
              EXHIBITION
            </span>

            <span
              className={cn('header-page')}
              onClick={() => {
                // history.push('/online-exhibition');
                window.alert('We are preparing for service.');
              }}
              style={{
                color: (artist || main || exhibition) && !isTop ? '#fff' : '',
              }}
            >
              ONLINE EXHIBITION
            </span>

            <span
              className={cn('header-logo')}
              onClick={() => history.push('/')}
              style={{
                color: (artist || main || exhibition) && !isTop ? '#fff' : '',
              }}
            >
              LEE & BAE
            </span>

            <span
              className={cn('header-page')}
              onClick={() => history.push('/artfair')}
              style={{
                color: (artist || main || exhibition) && !isTop ? '#fff' : '',
              }}
            >
              ART FAIR
            </span>

            <span
              className={cn('header-page')}
              onClick={() => {
                history.push('/publications');
              }}
              style={{
                color: (artist || main || exhibition) && !isTop ? '#fff' : '',
              }}
            >
              PUBLICATIONS
            </span>

            <span
              className={cn('header-page')}
              onClick={() => history.push('/about')}
              style={{
                color: (artist || main || exhibition) && !isTop ? '#fff' : '',
              }}
            >
              ABOUT
            </span>
          </div>

          <div
            className={cn('contents')}
            style={{
              maxWidth: artist || main || exhibition ? '' : '1920px',
            }}
          >
            {children}
          </div>

          <div className={cn('footer')}>
            <div className={cn('footer-box')} style={{ width: '50%' }}>
              <span className={cn('footer-box-title')}>About</span>

              <div className={cn('footer-box-text')}>
                LEE & BAE is committed to developing international
                <br />
                collaboration and exchange of art.
              </div>

              <div className={cn('footer-box-text_2')}>
                127 Jwasuyeong-ro, Suyeong-gu, Busan, Korea
                <br />
                (48214)
              </div>

              <div className={cn('footer-box-text_wrap')}>
                <span className={cn('footer-box-text_wrap-title')}>
                  OPEN ON
                </span>

                <span className={cn('footer-box-text_wrap-text')}>
                  TUESDAY - SATURDAY 10:30 - 18:30
                </span>
              </div>

              <div className={cn('footer-box-text_wrap')}>
                <span className={cn('footer-box-text_wrap-title')}>
                  LUNCH BREAK
                </span>

                <span className={cn('footer-box-text_wrap-text')}>
                  13:00 - 14:00
                </span>
              </div>

              <div className={cn('footer-box-text_wrap')}>
                <span className={cn('footer-box-text_wrap-title')}>
                  CLOSED ON
                </span>

                <span className={cn('footer-box-text_wrap-text')}>
                  SUNDAY & MONDAY
                </span>
              </div>
            </div>

            <div className={cn('footer-box')} style={{ width: '25%' }}>
              <span className={cn('footer-box-title')}>Follow us</span>

              <div
                className={cn('footer-box-icon_wrap')}
                onClick={() =>
                  window.open(
                    'https://www.artsy.net/partner/lee-and-bae',
                    '_blank'
                  )
                }
              >
                <img
                  className={cn('footer-box-icon_wrap-icon')}
                  src={ARTSY}
                  alt='icon'
                  draggable={false}
                />

                <span className={cn('footer-box-icon_wrap-text')}>ARTSY</span>
              </div>

              <div
                className={cn('footer-box-icon_wrap')}
                onClick={() =>
                  window.open(
                    'https://facebook.com/galleryleebaekorea',
                    '_blank'
                  )
                }
              >
                <img
                  className={cn('footer-box-icon_wrap-icon')}
                  src={FACEBOOK}
                  alt='icon'
                  draggable={false}
                />

                <span className={cn('footer-box-icon_wrap-text')}>
                  FACEBOOK
                </span>
              </div>

              <div
                className={cn('footer-box-icon_wrap')}
                onClick={() =>
                  window.open('https://www.instagram.com/leeandbae', '_blank')
                }
              >
                <img
                  className={cn('footer-box-icon_wrap-icon')}
                  src={INSTAGRAM}
                  alt='icon'
                  draggable={false}
                />

                <span className={cn('footer-box-icon_wrap-text')}>
                  INSTAGRAM
                </span>
              </div>
            </div>

            <div className={cn('footer-box')} style={{ width: '25%' }}>
              <span className={cn('footer-box-title')}>Contact</span>

              <span className={cn('footer-box-contact_title')}>TEL</span>

              <span className={cn('footer-box-contact_text')}>
                +82 51 756 2111
              </span>

              <span className={cn('footer-box-contact_title')}>E-mail</span>

              <span className={cn('footer-box-contact_text')}>
                galleryleebaekorea@gmail.com
              </span>
            </div>
          </div>
        </Box>
      ) : (
        <MobileBox
          style={{
            backgroundColor: location.pathname.includes('/online-exhibition')
              ? '#F5F2EB'
              : '',
          }}
        >
          <div
            className={cn('header')}
            style={{
              backgroundColor:
                location.pathname.includes('/online-exhibition') && !isMenu
                  ? '#F5F2EB'
                  : '',
            }}
          >
            <span
              className={cn('header-logo')}
              onClick={() => history.push('/')}
            >
              {handleLogo()}
            </span>

            <img
              className={cn('header-icon')}
              src={MENU}
              alt='icon'
              draggable={false}
              onClick={() => setIsMenu(!isMenu)}
            />
          </div>

          <div className={cn('contents')}>{children}</div>

          <div className={cn('footer')}>
            <div className={cn('footer-box')}>
              <span className={cn('footer-box-title')}>About</span>

              <div className={cn('footer-box-text')}>
                LEE & BAE is committed to developing international
                <br />
                collaboration and exchange of art.
              </div>

              <div className={cn('footer-box-text_2')}>
                127 Jwasuyeong-ro, Suyeong-gu, Busan, Korea
                <br />
                (48214)
              </div>

              <div className={cn('footer-box-text_wrap')}>
                <span className={cn('footer-box-text_wrap-title')}>
                  OPEN ON
                </span>

                <span className={cn('footer-box-text_wrap-text')}>
                  TUE - SAT 10:30 - 18:30
                </span>
              </div>

              <div className={cn('footer-box-text_wrap')}>
                <span className={cn('footer-box-text_wrap-title')}>
                  LUNCH BREAK
                </span>

                <span className={cn('footer-box-text_wrap-text')}>
                  13:00 - 14:00
                </span>
              </div>

              <div className={cn('footer-box-text_wrap')}>
                <span className={cn('footer-box-text_wrap-title')}>
                  CLOSED ON
                </span>

                <span className={cn('footer-box-text_wrap-text')}>
                  SUNDAY & MONDAY
                </span>
              </div>
            </div>

            <div className={cn('footer-box')}>
              <span className={cn('footer-box-title')}>Follow us</span>

              <div
                className={cn('footer-box-icon_wrap')}
                onClick={() =>
                  window.open(
                    'https://www.artsy.net/partner/lee-and-bae',
                    '_blank'
                  )
                }
              >
                <img
                  className={cn('footer-box-icon_wrap-icon')}
                  src={ARTSY}
                  alt='icon'
                  draggable={false}
                />

                <span className={cn('footer-box-icon_wrap-text')}>ARTSY</span>
              </div>

              <div
                className={cn('footer-box-icon_wrap')}
                onClick={() =>
                  window.open(
                    'https://facebook.com/galleryleebaekorea',
                    '_blank'
                  )
                }
              >
                <img
                  className={cn('footer-box-icon_wrap-icon')}
                  src={FACEBOOK}
                  alt='icon'
                  draggable={false}
                />

                <span className={cn('footer-box-icon_wrap-text')}>
                  FACEBOOK
                </span>
              </div>

              <div
                className={cn('footer-box-icon_wrap')}
                onClick={() =>
                  window.open('https://www.instagram.com/leeandbae', '_blank')
                }
              >
                <img
                  className={cn('footer-box-icon_wrap-icon')}
                  src={INSTAGRAM}
                  alt='icon'
                  draggable={false}
                />

                <span className={cn('footer-box-icon_wrap-text')}>
                  INSTAGRAM
                </span>
              </div>
            </div>

            <div className={cn('footer-box')}>
              <span className={cn('footer-box-title')}>Contact</span>

              <span className={cn('footer-box-contact_title')}>TEL</span>

              <span className={cn('footer-box-contact_text')}>
                +82 51 756 2111
              </span>

              <span className={cn('footer-box-contact_title')}>E-mail</span>

              <span className={cn('footer-box-contact_text')}>
                galleryleebaekorea@gmail.com
              </span>
            </div>
          </div>

          {isMenu ? (
            <div className={cn('menu')}>
              <span
                className={cn('menu-page')}
                onClick={() => history.push('/')}
              >
                HOME
              </span>

              <span
                className={cn('menu-page')}
                onClick={() => history.push('/artist')}
              >
                ARTIST
              </span>

              <span
                className={cn('menu-page')}
                onClick={() => history.push('/exhibition/onview')}
              >
                EXHIBITION
              </span>

              <span
                className={cn('menu-page')}
                onClick={() => {
                  // history.push('/online-exhibition');
                  window.alert('We are preparing for service.');
                }}
              >
                ONLINE EXHIBITION
              </span>

              <span
                className={cn('menu-page')}
                onClick={() => history.push('/artfair')}
              >
                ART FAIR
              </span>

              <span
                className={cn('menu-page')}
                onClick={() => {
                  history.push('/publications');
                }}
              >
                PUBLICATIONS
              </span>

              <span
                className={cn('menu-page')}
                onClick={() => history.push('/about')}
              >
                ABOUT
              </span>
            </div>
          ) : (
            <></>
          )}
        </MobileBox>
      )}

      {artwork ? <Artwork /> : <></>}

      {publications ? <PublicationsModal /> : <></>}
    </>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .header {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;

    &-page {
      font-size: 16px;
      font-weight: 400;
      user-select: none;
      cursor: pointer;
    }

    &-logo {
      font-size: 30px;
      font-weight: 500;
      letter-spacing: 5px;
      user-select: none;
      cursor: pointer;
    }
  }

  .contents {
    width: 100%;
    height: fit-content;
    min-height: 100%;
  }

  .footer {
    width: 100%;
    max-width: 1920px;
    height: fit-content;
    border-top: 1px solid #ddd;
    display: flex;
    flex-direction: row;
    padding: 50px 100px;

    &-box {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      &-title {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 50px;
      }

      &-text,
      &-text_2 {
        font-size: 16px;
        line-height: 1.8;
      }

      &-text_2 {
        color: #898989;
        margin-bottom: 20px;
      }

      &-text_wrap {
        width: 400px;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        &-title {
          font-size: 16px;
          font-weight: 500;
        }
      }

      &-icon_wrap {
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-bottom: 30px;
        cursor: pointer;
        user-select: none;

        &:hover {
          .footer-box-icon_wrap-text {
            text-decoration: underline;
          }
        }

        &-icon {
          width: 20px;
          height: auto;
          margin-right: 20px;
        }

        &-text {
          font-size: 16px;
          font-weight: 400;
        }
      }

      &-contact_title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      &-contact_text {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 30px;
      }
    }
  }
`;

const MobileBox = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  .header {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    background-color: #fff;
    padding: 0 20px;

    &-logo {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 3px;
      user-select: none;
      margin: 0 auto 0 auto;
    }

    &-icon {
      width: 20px;
      height: auto;
      margin: 0;
    }
  }

  .contents {
    width: 100%;
    height: fit-content;
    margin-top: 100px;
  }

  .footer {
    width: 100%;
    height: fit-content;
    border-top: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;

    &-box {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      &-title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
      }

      &-text,
      &-text_2 {
        line-height: 1.8;
      }

      &-text_2 {
        color: #898989;
        margin-bottom: 10px;
      }

      &-text_wrap {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 30px;
        }

        &-title {
          font-weight: 500;
        }
      }

      &-icon_wrap {
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-bottom: 20px;
        cursor: pointer;
        user-select: none;

        &:last-child {
          margin-bottom: 30px;
        }

        &-icon {
          width: 20px;
          height: auto;
          margin-right: 10px;
        }

        &-text {
          font-size: 15px;
          font-weight: 400;
        }
      }

      &-contact_title {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      &-contact_text {
        font-weight: 400;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .menu {
    width: 100%;
    height: fit-content;
    min-height: calc(100vh - 100px);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    background-color: #fff;

    &-page {
      width: 100%;
      height: fit-content;
      text-align: right;
      font-size: 20px;
      padding: 20px;
      letter-spacing: 1px;
      border-top: 1px solid #ddd;
      user-select: none;
    }
  }
`;
