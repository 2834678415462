import styled from 'styled-components';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { usePublications } from '../../context/Publications';

export default function Publications() {
  const [list, setList] = useState<any[]>([]);

  const { handlePublications } = usePublications();

  const getPublications = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/publications/purchases`)
      .then((response) => {
        setList(response.data.data);
      });
  };

  useEffect(() => {
    getPublications();
  }, []);

  return (
    <Box>
      <div className={cn('publications_contents')}>
        {list.map((data: any, index) => {
          return (
            <div key={index} className={cn('publications_contents-item')}>
              <img
                src={data.image}
                alt='publications_img'
                className={cn('publications_contents-item-img')}
                draggable={false}
                onClick={() => handlePublications(data)}
              />

              <span
                className={cn('publications_contents-item-title')}
                onClick={() => handlePublications(data)}
              >
                {data.title}
              </span>

              <span
                className={cn('publications_contents-item-sub_title')}
                onClick={() => handlePublications(data)}
              >
                {data.sub_title}
              </span>
            </div>
          );
        })}
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;

  .publications_contents {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    row-gap: 50px;
    column-gap: 30px;
    padding: 20px;
    margin-bottom: 30px;

    &-item {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-img {
        width: 100%;
        height: 200px;
        object-fit: contain;
        object-position: center;
        margin-bottom: 20px;
        cursor: pointer;
      }

      &-title {
        width: fit-content;
        height: fit-content;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
        cursor: pointer;
        user-select: none;
      }

      &-sub_title {
        width: fit-content;
        height: fit-content;
        font-family: 'Noto Serif';
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 1px;
        color: #707070;
        cursor: pointer;
        user-select: none;
      }
    }
  }
`;
