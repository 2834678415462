import styled from 'styled-components';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getUTCDate } from '../../library/date.lib';

export default function ExhibitionUpcoming() {
  const history = useHistory();

  const [exhibtions, setExhibitions] = useState<any[]>([]);

  const getExhibition = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition/up`)
      .then(async (response) => {
        setExhibitions(response.data.data.upcoming);
      });
  };

  useEffect(() => {
    getExhibition();
  }, []);

  return (
    <Box>
      <div className={cn('btn_wrap')}>
        <span
          className={cn('btn_wrap-btn')}
          onClick={() => history.push('/exhibition/onview')}
        >
          On View
        </span>

        <span
          className={cn('btn_wrap-btn')}
          onClick={() => history.push('/exhibition-upcoming')}
        >
          Upcoming
        </span>

        <span
          className={cn('btn_wrap-btn')}
          onClick={() => history.push('/exhibition-past')}
        >
          Past
        </span>
      </div>

      {exhibtions.length ? (
        <div className={cn('list')}>
          {exhibtions.map((exhibition, index) => {
            return (
              <div key={index} className={cn('list-item')}>
                <div
                  className={cn('list-item-color', {
                    [`list-item-color-${(index + 1) % 4}`]:
                      !exhibition.thumbnail,
                  })}
                  style={{
                    backgroundImage: exhibition.thumbnail
                      ? `url('${exhibition.thumbnail}')`
                      : '',
                  }}
                >
                  Upcoming
                </div>

                <div className={cn('list-item-wrap')}>
                  <span className={cn('list-item-wrap-title')}>
                    {exhibition.title}
                  </span>

                  <span className={cn('list-item-wrap-date')}>
                    {`${getUTCDate(String(exhibition.date).split('|')[0])
                      .format('MMM DD YYYY')
                      .toString()} - ${getUTCDate(
                      String(exhibition.date).split('|')[1]
                    )
                      .format('MMM DD YYYY')
                      .toString()}`}
                  </span>

                  <span className={cn('list-item-wrap-location')}>
                    {exhibition.location}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <span className={cn('empty')}>coming soon</span>
      )}
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .btn_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 30px 0;

    &-btn {
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      user-select: none;
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .empty {
    width: fit-content;
    height: fit-content;
    margin: 50px auto;
    font-size: 18px;
    font-style: italic;
    color: #828282;
    user-select: none;
  }

  .list {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    &-item {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px 0;
      border-bottom: 1px solid #ddd;

      &:last-child {
        border-bottom: 0;
      }

      &-color {
        width: 100%;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 5px;
        background-size: cover;
        background-position: center;

        &-1 {
          background: radial-gradient(
            circle,
            rgba(236, 124, 124, 1) 5%,
            rgba(255, 255, 255, 1) 50%
          );
        }

        &-2 {
          background: radial-gradient(
            circle,
            rgba(255, 234, 122, 1) 5%,
            rgba(255, 255, 255, 1) 50%
          );
        }

        &-3 {
          background: radial-gradient(
            circle,
            rgba(155, 229, 143, 1) 5%,
            rgba(255, 255, 255, 1) 50%
          );
        }

        &-4 {
          background: radial-gradient(
            circle,
            rgba(106, 141, 232, 1) 5%,
            rgba(255, 255, 255, 1) 50%
          );
        }
      }

      &-wrap {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &-title {
          width: fit-content;
          height: fit-content;
          font-family: 'Noto Serif';
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 10px;
        }

        &-date {
          color: #828282;
          letter-spacing: 1px;
          margin-bottom: 5px;
        }

        &-location {
          color: #828282;
          letter-spacing: 1px;
        }
      }
    }
  }
`;
