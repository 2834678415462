import styled from 'styled-components';
import cn from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';

import { useHistory, useParams } from 'react-router-dom';
import { useArtwork } from '../../context/Artwork';
import { getUTCDate } from '../../library/date.lib';

export default function ArtistDetail() {
  const { id }: any = useParams();
  const history = useHistory();

  const { handleArtwork } = useArtwork();

  const [artist, setArtist] = useState<any>(null);

  const handleSentence = (text: string) => {
    return text
      .split('\n')
      .map((text: string, index: number, list: string[]) => {
        if (index === list.length - 1) {
          return text;
        }

        return (
          <Fragment key={index}>
            {text}
            <br />
          </Fragment>
        );
      });
  };

  const getArtist = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artist/${id}`)
      .then(async (response) => {
        setArtist(response.data.data);
      });
  };

  useEffect(() => {
    getArtist();
  }, []);

  return (
    <>
      {artist ? (
        <Box>
          <div className={cn('profile')}>
            <span className={cn('profile-name')}>{artist.name}</span>

            <span
              className={cn('profile-text')}
              style={{ marginBottom: '5px' }}
            >
              {artist.country}
            </span>

            <span
              className={cn('profile-text')}
              style={{ marginBottom: '50px' }}
            >
              b. {artist.year_of_birth}
            </span>

            <img
              className={cn('profile-img')}
              src={
                artist.thumbnail
                  ? artist.thumbnail
                  : artist.artworks[0].thumbnail
              }
              alt='artwork_img'
              draggable={false}
            />
          </div>

          <div className={cn('wrap')}>
            <span className={cn('wrap-title')}>About</span>

            <div className={cn('wrap-text')}>
              {handleSentence(artist.about)}
            </div>

            <span
              className={cn('wrap-cv')}
              onClick={() => window.open(artist.cv, '_blank')}
            >
              Download CV
            </span>
          </div>

          {artist.exhibition_list.length ? (
            <div className={cn('wrap2_e')}>
              <span className={cn('wrap2_e-title')}>Exhibition</span>

              <div className={cn('wrap2_e-list')}>
                {artist.exhibition_list.map(
                  (exhibition: any, index: number) => {
                    if (index > 5) return <></>;

                    return (
                      <div key={index} className={cn('wrap2_e-list-item')}>
                        <img
                          src={exhibition.thumbnail}
                          alt='exhibition_img'
                          draggable={false}
                          className={cn('wrap2_e-list-item-img')}
                          onClick={() =>
                            history.push(`/exhibition/${exhibition.id}`)
                          }
                        />

                        <span
                          className={cn('wrap2_e-list-item-title')}
                          onClick={() =>
                            history.push(`/exhibition/${exhibition.id}`)
                          }
                        >
                          {exhibition.title}
                        </span>

                        <span
                          className={cn('wrap2_e-list-item-date')}
                          onClick={() =>
                            history.push(`/exhibition/${exhibition.id}`)
                          }
                        >
                          {`${getUTCDate(String(exhibition.date).split('|')[0])
                            .format('MMM DD YYYY')
                            .toString()} - ${getUTCDate(
                            String(exhibition.date).split('|')[1]
                          )
                            .format('MMM DD YYYY')
                            .toString()}`}
                        </span>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          ) : (
            <></>
          )}

          {artist.artfair_list.length ? (
            <div className={cn('wrap2')}>
              <span className={cn('wrap2-title')}>Art Fair</span>

              <div className={cn('wrap2-list')}>
                {artist.artfair_list.map((artfair: any, index: number) => {
                  if (index > 3) return <></>;

                  return (
                    <div key={index} className={cn('wrap2-list-item')}>
                      <img
                        src={artfair.thumbnail}
                        alt='artfair_img'
                        draggable={false}
                        className={cn('wrap2-list-item-img')}
                        onClick={() => history.push(`/artfair/${artfair.id}`)}
                      />

                      <span
                        className={cn('wrap2-list-item-title')}
                        onClick={() => history.push(`/artfair/${artfair.id}`)}
                      >
                        {artfair.title}
                      </span>

                      <span
                        className={cn('wrap2-list-item-date')}
                        onClick={() => history.push(`/artfair/${artfair.id}`)}
                      >
                        {`${getUTCDate(String(artfair.date).split('|')[0])
                          .format('MMM DD YYYY')
                          .toString()} - ${getUTCDate(
                          String(artfair.date).split('|')[1]
                        )
                          .format('MMM DD YYYY')
                          .toString()}`}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <></>
          )}

          {artist.artworks.length ? (
            <div className={cn('wrap3')}>
              <span className={cn('wrap3-title')}>Artwork</span>

              <div className={cn('wrap3-list')}>
                {artist.artworks.map((artwork: any, index: number) => {
                  return (
                    <div key={index} className={cn('wrap3-list-item')}>
                      <img
                        src={artwork.thumbnail}
                        alt='artfair_img'
                        draggable={false}
                        className={cn('wrap3-list-item-img')}
                        onClick={() => handleArtwork(artwork)}
                      />

                      <span
                        className={cn('wrap3-list-item-title')}
                        onClick={() => handleArtwork(artwork)}
                      >
                        {artwork.title}
                      </span>

                      <span
                        className={cn('wrap3-list-item-year')}
                        onClick={() => handleArtwork(artwork)}
                      >
                        {artwork.year}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .profile {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 50px 30px 100px 30px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 95%,
      rgba(255, 255, 255, 1) 100%
    );

    &-name {
      font-family: 'Noto Serif';
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 10px;
      color: #fff;
    }

    &-text {
      font-size: 16px;
      color: #fff;
    }

    &-img {
      width: 100%;
      height: auto;
    }
  }

  .wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 50px 20px;
    border-bottom: 1px solid #ddd;

    &-title {
      font-family: 'Noto Serif';
      font-size: 18px;
      font-weight: 600;
      user-select: none;
      margin-bottom: 30px;
    }

    &-text {
      line-height: 2;
    }

    &-cv {
      width: fit-content;
      height: fit-content;
      font-size: 16px;
      font-weight: 400;
      margin-top: 20px;
      text-decoration: underline;
      user-select: none;
    }
  }

  .wrap2 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 50px 20px;
    border-bottom: 1px solid #ddd;

    &-title {
      font-family: 'Noto Serif';
      font-size: 18px;
      font-weight: 600;
      user-select: none;
      margin-bottom: 30px;
    }

    &-list {
      width: 100%;
      height: fit-content;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      row-gap: 30px;
      column-gap: 20px;

      &-item {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;

        &-img {
          width: 100%;
          height: 250px;
          object-fit: cover;
          object-position: center;
          margin-bottom: 10px;
          cursor: pointer;
        }

        &-title {
          width: fit-content;
          height: fit-content;
          font-size: 15px;
          font-weight: 500;
          margin-top: 10px;
          margin-bottom: 5px;
          user-select: none;
        }

        &-date {
          width: fit-content;
          height: fit-content;
          font-weight: 200;
          user-select: none;
        }
      }
    }
  }

  .wrap2_e {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 50px 20px;
    border-bottom: 1px solid #ddd;

    &-title {
      font-family: 'Noto Serif';
      font-size: 18px;
      font-weight: 600;
      user-select: none;
      margin-bottom: 30px;
    }

    &-list {
      width: 100%;
      height: fit-content;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      row-gap: 30px;
      column-gap: 20px;

      &-item {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;

        &-img {
          width: 100%;
          height: 150px;
          object-fit: cover;
          object-position: center;
          margin-bottom: 10px;
          cursor: pointer;
        }

        &-title {
          width: fit-content;
          height: fit-content;
          font-size: 15px;
          font-weight: 500;
          margin-top: 10px;
          margin-bottom: 5px;
          user-select: none;
        }

        &-date {
          width: fit-content;
          height: fit-content;
          font-weight: 200;
          user-select: none;
        }
      }
    }
  }

  .wrap3 {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 50px 20px;

    &-title {
      font-family: 'Noto Serif';
      font-size: 18px;
      font-weight: 600;
      user-select: none;
      margin-bottom: 30px;
    }

    &-list {
      width: 100%;
      height: fit-content;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      row-gap: 30px;
      column-gap: 20px;

      &-item {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: center;
        flex-direction: column;

        &-img {
          width: 100%;
          height: auto;
          object-fit: contain;
          object-position: bottom;
          margin-bottom: 10px;
        }

        &-title {
          width: fit-content;
          height: fit-content;
          font-weight: 500;
          margin-top: auto;
          margin-bottom: 10px;
          text-align: center;
          user-select: none;
        }

        &-year {
          width: fit-content;
          height: fit-content;
          user-select: none;
        }
      }
    }
  }
`;
