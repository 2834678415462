import styled from 'styled-components';
import cn from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';

import ICON_1 from '../../static/images/location.svg';
import ICON_2 from '../../static/images/calendar.svg';
import { useHistory } from 'react-router-dom';
import { getUTCDate } from '../../library/date.lib';

export default function ArtFair() {
  const history = useHistory();

  const [currentList, setCurrentList] = useState<any[]>([]);
  const [upcomingList, setUpcomingList] = useState<any[]>([]);
  const [pastList, setPastList] = useState<any[]>([]);

  const handleArtFair = (id: any) => {
    history.push(`/artfair/${id}`);
  };

  const getArtFair = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artfair/cup`)
      .then((response) => {
        setCurrentList(response.data.data.current);
        setUpcomingList(response.data.data.upcoming);
        setPastList(response.data.data.past);
      });
  };

  useEffect(() => {
    getArtFair();
  }, []);

  return (
    <Box>
      <span className={cn('title')}>Art Fair</span>

      <div className={cn('contents')}>
        <span className={cn('contents-title')}>Current</span>

        <div className={cn('contents-current_list')}>
          {currentList.length ? (
            currentList.map((data, index) => {
              return (
                <div key={index} className={cn('contents-current_list-item')}>
                  <img
                    className={cn('contents-current_list-item-img')}
                    src={data.thumbnail}
                    alt='current_img'
                    draggable={false}
                    onClick={() => handleArtFair(data.id)}
                  />

                  <span
                    className={cn('contents-current_list-item-title')}
                    onClick={() => handleArtFair(data.id)}
                  >
                    {data.title}
                  </span>

                  <div className={cn('contents-current_list-item-wrap')}>
                    <span
                      className={cn('contents-current_list-item-icon_wrap')}
                    >
                      <img
                        className={cn(
                          'contents-current_list-item-icon_wrap-icon'
                        )}
                        src={ICON_1}
                        alt='icon'
                        style={{ width: '16px', height: 'auto' }}
                        draggable={false}
                      />

                      <span
                        className={cn(
                          'contents-current_list-item-icon_wrap-text'
                        )}
                      >
                        {data.location}
                      </span>
                    </span>

                    <span
                      className={cn('contents-current_list-item-icon_wrap')}
                      style={{ marginLeft: '50px' }}
                    >
                      <img
                        className={cn(
                          'contents-current_list-item-icon_wrap-icon'
                        )}
                        src={ICON_2}
                        alt='icon'
                        style={{ width: '18px', height: 'auto' }}
                        draggable={false}
                      />

                      <span
                        className={cn(
                          'contents-current_list-item-icon_wrap-text'
                        )}
                      >
                        {`${getUTCDate(String(data.date).split('|')[0])
                          .format('MM.DD.YYYY')
                          .toString()} - ${getUTCDate(
                          String(data.date).split('|')[1]
                        )
                          .format('MM.DD.YYYY')
                          .toString()}`}
                      </span>
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <span className={cn('contents-current_list-empty')}>
              There is currently no ongoing art fair.
            </span>
          )}
        </div>

        <div className={cn('contents-line')} />

        <div className={cn('contents-title')}>Upcoming</div>

        <div className={cn('contents-upcoming_list')}>
          {upcomingList.map((data, index) => {
            if ((index + 1) % 2 === 0) {
              return (
                <div key={index} className={cn('contents-upcoming_list-item')}>
                  <div
                    className={cn(
                      'contents-upcoming_list-item-wrap',
                      'contents-upcoming_list-item-wrap_reverse'
                    )}
                  >
                    <span
                      className={cn('contents-upcoming_list-item-wrap-title')}
                      onClick={() => handleArtFair(data.id)}
                    >
                      {data.title}
                    </span>

                    <span
                      className={cn('contents-upcoming_list-item-wrap-text')}
                    >
                      {data.location}
                    </span>

                    <span
                      className={cn('contents-upcoming_list-item-wrap-text')}
                    >{`${getUTCDate(String(data.date).split('|')[0])
                      .format('MM.DD')
                      .toString()} - ${getUTCDate(
                      String(data.date).split('|')[1]
                    )
                      .format('MM.DD.YYYY')
                      .toString()}`}</span>
                  </div>

                  <img
                    className={cn('contents-upcoming_list-item-img')}
                    src={data.thumbnail}
                    alt='upcoming_img'
                    draggable={false}
                    onClick={() => handleArtFair(data.id)}
                  />
                </div>
              );
            } else {
              return (
                <div key={index} className={cn('contents-upcoming_list-item')}>
                  <img
                    className={cn('contents-upcoming_list-item-img')}
                    src={data.thumbnail}
                    alt='upcoming_img'
                    draggable={false}
                    onClick={() => handleArtFair(data.id)}
                  />

                  <div className={cn('contents-upcoming_list-item-wrap')}>
                    <span
                      className={cn('contents-upcoming_list-item-wrap-title')}
                      onClick={() => handleArtFair(data.id)}
                    >
                      {data.title}
                    </span>

                    <span
                      className={cn('contents-upcoming_list-item-wrap-text')}
                    >
                      {data.location}
                    </span>

                    <span
                      className={cn('contents-upcoming_list-item-wrap-text')}
                    >{`${getUTCDate(String(data.date).split('|')[0])
                      .format('MM.DD')
                      .toString()} - ${getUTCDate(
                      String(data.date).split('|')[1]
                    )
                      .format('MM.DD.YYYY')
                      .toString()}`}</span>
                  </div>
                </div>
              );
            }
          })}
        </div>

        <div className={cn('contents-line')} />

        <div className={cn('contents-title')}>Past</div>

        <div className={cn('contents-past_list')}>
          {pastList.map((data, index) => {
            return (
              <div key={index} className={cn('contents-past_list-item')}>
                <img
                  className={cn('contents-past_list-item-img')}
                  src={data.thumbnail}
                  alt='past_img'
                  draggable={false}
                  onClick={() => handleArtFair(data.id)}
                />

                <div className={cn('contents-past_list-item-wrap')}>
                  <span
                    className={cn('contents-past_list-item-wrap-title')}
                    onClick={() => handleArtFair(data.id)}
                  >
                    {data.title}
                  </span>

                  {/* <span className={cn('contents-past_list-item-wrap-subtitle')}>
                    ARTISTS
                  </span> */}

                  <div className={cn('contents-past_list-item-wrap-artists')}>
                    {data.artists.map((artist: any, index: number) => {
                      if (data.artists.length === index + 1) {
                        return <Fragment key={index}>{artist.name}</Fragment>;
                      }

                      return <Fragment key={index}>{artist.name}, </Fragment>;
                    })}
                  </div>

                  <div className={cn('contents-past_list-item-wrap-icon_wrap')}>
                    <img
                      className={cn(
                        'contents-past_list-item-wrap-icon_wrap-icon'
                      )}
                      src={ICON_1}
                      alt='icon'
                      style={{ width: '12px', height: 'auto' }}
                      draggable={false}
                    />

                    <span
                      className={cn(
                        'contents-past_list-item-wrap-icon_wrap-text'
                      )}
                    >
                      {data.location}
                    </span>
                  </div>
                </div>

                <span className={cn('contents-past_list-item-date_wrap')}>
                  {/* <span
                    className={cn('contents-past_list-item-date_wrap-title')}
                  >
                    DATE
                  </span> */}

                  <span
                    className={cn('contents-past_list-item-date_wrap-date')}
                  >{`${getUTCDate(String(data.date).split('|')[0])
                    .format('MM.DD.YYYY')
                    .toString()} - ${getUTCDate(String(data.date).split('|')[1])
                    .format('MM.DD.YYYY')
                    .toString()}`}</span>
                </span>

                <span
                  className={cn('contents-past_list-item-text')}
                  onClick={() => handleArtFair(data.id)}
                >
                  Read More
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  padding: 100px 150px;

  .title {
    font-family: 'Noto Serif';
    font-size: 26px;
    font-weight: 400;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 50px;
  }

  .contents {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    &-title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 50px;
    }

    &-line {
      width: 100%;
      height: 1px;
      background-color: #ddd;
      margin: 50px 0;
    }

    &-current_list {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-empty {
        width: fit-content;
        height: fit-content;
        padding: 20px;
        margin: auto;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 1px;
      }

      &-item {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;

        &:last-child {
          margin-bottom: 0;
        }

        &-img {
          width: 100%;
          height: 700px;
          object-fit: cover;
          object-position: center;
          margin-bottom: 30px;
          cursor: pointer;
        }

        &-title {
          width: fit-content;
          height: fit-content;
          font-size: 20px;
          font-weight: 500;
          margin-bottom: 30px;
          cursor: pointer;
          user-select: none;
        }

        &-wrap {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: row;
        }

        &-icon_wrap {
          width: fit-content;
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;

          &-icon {
            margin-right: 20px;
          }

          &-text {
            font-weight: 400;
          }
        }
      }
    }

    &-upcoming_list {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-item {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: row;
        margin-bottom: 100px;

        &:last-child {
          margin-bottom: 0;
        }

        &-img {
          width: 50%;
          height: 500px;
          object-fit: cover;
          object-position: center;
          cursor: pointer;
        }

        &-wrap_reverse {
          align-items: flex-end;
          margin-left: 0 !important;
          margin-right: 50px !important;
          text-align: end;
        }

        &-wrap {
          width: 50%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          margin-left: 50px;

          &-title {
            width: fit-content;
            height: fit-content;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
            cursor: pointer;
            user-select: none;
          }

          &-text {
            font-size: 18px;
          }
        }
      }
    }

    &-past_list {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-item {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        position: relative;
        padding-bottom: 30px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 30px;
        z-index: 0;

        &:last-child {
          margin-bottom: 0;
          border-bottom: 0;
        }

        &-img {
          width: 400px;
          height: 300px;
          object-fit: cover;
          object-position: center;
          cursor: pointer;
          margin-right: 30px;
        }

        &-wrap {
          width: calc(100% - 430px);
          height: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;

          &-title {
            width: fit-content;
            height: fit-content;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 30px;
            cursor: pointer;
            user-select: none;
          }

          &-subtitle {
            width: fit-content;
            height: fit-content;
            font-weight: 500;
            margin-bottom: 10px;
          }

          &-artists {
            width: 400px;
            height: fit-content;
            line-height: 1.5;
            margin-bottom: 30px;
          }

          &-icon_wrap {
            width: fit-content;
            height: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;

            &-icon {
              margin-right: 10px;
            }
          }
        }

        &-date_wrap {
          width: fit-content;
          height: fit-content;
          display: flex;
          justify-items: center;
          align-items: center;
          flex-direction: row;
          position: absolute;
          top: 0;
          right: 0;

          &-title {
            font-weight: 600;
            margin-right: 10px;
          }

          &-date {
            color: #898989;
            font-weight: 400;
          }
        }

        &-text {
          width: fit-content;
          height: fit-content;
          position: absolute;
          right: 0;
          bottom: 30px;
          font-size: 16px;
          font-weight: 500;
          cursor: pointer;
          user-select: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;
