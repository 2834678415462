import styled from 'styled-components';
import cn from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

export default function Journal() {
  const history = useHistory();

  const [journalList, setJournalList] = useState<any[]>([]);

  const handleSentence = (text: string) => {
    return text
      .split('\n')
      .map((text: string, index: number, list: string[]) => {
        if (index === list.length - 1) {
          return text;
        }

        return (
          <Fragment key={index}>
            {text}
            <br />
          </Fragment>
        );
      });
  };

  const handleJournal = (id: any, type: any) => {
    switch (type) {
      case 'journal':
        history.push(`/journal/${id}`);

        return;

      case 'exhibition':
        history.push(`/exhibition/${id}`);

        return;

      case 'artfair':
        history.push(`/artfair/${id}`);

        return;

      default:
        break;
    }
  };

  const getJournal = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/news/journal-fe`)
      .then((response) => {
        const arr: any[] = [];

        for (let i = 0; i < response.data.data.length; i += 3) {
          arr.push(response.data.data.slice(i, i + 3));
        }

        setJournalList(arr);
      });
  };

  useEffect(() => {
    getJournal();
  }, []);

  return (
    <Box>
      <span className={cn('title')}>Journal</span>

      <span className={cn('subtitle')}>
        ARTFUL INSIGHTS : <span className={cn('subtitle-bold')}>LEE & BAE</span>
        'S PULSE ON
      </span>

      <div className={cn('contents')}>
        {journalList.map((arr: any[], index) => {
          return (
            <div key={index} className={cn('contents-wrap')}>
              {arr.map((journal, index) => {
                return (
                  <div
                    key={index}
                    className={cn('contents-wrap-item')}
                    onClick={() => handleJournal(journal.id, journal.type)}
                  >
                    <span className={cn('contents-wrap-item-title')}>
                      {journal.title}
                    </span>

                    <span className={cn('contents-wrap-item-about')}>
                      {handleSentence(journal.about)}
                    </span>

                    {journal.image || journal.thumbnail ? (
                      <img
                        src={journal.image || journal.thumbnail}
                        alt='journal'
                        className={cn('contents-wrap-item-img')}
                        draggable={false}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  padding: 100px 150px;

  .title {
    font-family: 'Noto Serif';
    font-size: 26px;
    font-weight: 400;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #ddd;
  }

  .subtitle {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 400;
    padding: 50px 0;

    &-bold {
      font-size: 30px;
      font-weight: 600;
    }
  }

  .contents {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    &-wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      border-top: 1px solid #898989;
      padding: 20px 0;

      &:last-child {
        border-bottom: 1px solid #898989;
      }

      &-item {
        width: 33.3%;
        height: 700px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        padding: 0 10px;
        border-right: 1px solid #898989;
        cursor: pointer;
        user-select: none;

        &:nth-child(3) {
          border-right: 0;
        }

        &-title {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 20px;
          padding: 0 20px;
        }

        &-about {
          width: 100%;
          height: fit-content;
          padding: 0 20px;
          line-height: 1.5;
          color: #898989;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 10;
          -webkit-box-orient: vertical;
          word-break: keep-all;
        }

        &-img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          object-position: center;
          margin-top: auto;
          margin-bottom: 0;
        }
      }
    }
  }
`;
