import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing:border-box;
    font-size: 13px;
    font-family: 'Noto Sans';
    font-weight: 300;
    line-height: 1.3;
    color:${(prop) => prop.theme.font};
  }

  #root{
    width:100%;
    height:100vh;
  }
`;

export default GlobalStyle;
