import styled from 'styled-components';
import cn from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';

import ICON_1 from '../../static/images/location.svg';
import ICON_2 from '../../static/images/calendar.svg';
import { useHistory } from 'react-router-dom';
import { getUTCDate } from '../../library/date.lib';

export default function ArtFair() {
  const history = useHistory();

  const [currentList, setCurrentList] = useState<any[]>([]);
  const [upcomingList, setUpcomingList] = useState<any[]>([]);
  const [pastList, setPastList] = useState<any[]>([]);

  const handleArtFair = (id: any) => {
    history.push(`/artfair/${id}`);
  };

  const getArtFair = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artfair/cup`)
      .then((response) => {
        setCurrentList(response.data.data.current);
        setUpcomingList(response.data.data.upcoming);
        setPastList(response.data.data.past);
      });
  };

  useEffect(() => {
    getArtFair();
  }, []);

  return (
    <Box>
      <div className={cn('artfair_contents')}>
        <span className={cn('artfair_contents-title')}>Current</span>

        <div className={cn('artfair_contents-current_list')}>
          {currentList.length ? (
            currentList.map((data, index) => {
              return (
                <div
                  key={index}
                  className={cn('artfair_contents-current_list-item')}
                >
                  <img
                    className={cn('artfair_contents-current_list-item-img')}
                    src={data.thumbnail}
                    alt='current_img'
                    draggable={false}
                    onClick={() => handleArtFair(data.id)}
                  />

                  <span
                    className={cn('artfair_contents-current_list-item-title')}
                    onClick={() => handleArtFair(data.id)}
                  >
                    {data.title}
                  </span>

                  <div
                    className={cn('artfair_contents-current_list-item-wrap')}
                  >
                    <span
                      className={cn(
                        'artfair_contents-current_list-item-icon_wrap'
                      )}
                    >
                      <img
                        className={cn(
                          'artfair_contents-current_list-item-icon_wrap-icon'
                        )}
                        src={ICON_1}
                        alt='icon'
                        style={{ width: '12px', height: 'auto' }}
                        draggable={false}
                      />

                      <span
                        className={cn(
                          'artfair_contents-current_list-item-icon_wrap-text'
                        )}
                      >
                        {data.location}
                      </span>
                    </span>

                    <span
                      className={cn(
                        'artfair_contents-current_list-item-icon_wrap'
                      )}
                      style={{ marginTop: '20px' }}
                    >
                      <img
                        className={cn(
                          'artfair_contents-current_list-item-icon_wrap-icon'
                        )}
                        src={ICON_2}
                        alt='icon'
                        style={{ width: '14px', height: 'auto' }}
                        draggable={false}
                      />

                      <span
                        className={cn(
                          'artfair_contents-current_list-item-icon_wrap-text'
                        )}
                      >
                        {`${getUTCDate(String(data.date).split('|')[0])
                          .format('MM.DD.YYYY')
                          .toString()} - ${getUTCDate(
                          String(data.date).split('|')[1]
                        )
                          .format('MM.DD.YYYY')
                          .toString()}`}
                      </span>
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <span className={cn('artfair_contents-current_list-empty')}>
              There is currently no ongoing art fair.
            </span>
          )}
        </div>

        <div className={cn('artfair_contents-line')} />

        <div className={cn('artfair_contents-title')}>Upcoming</div>

        <div className={cn('artfair_contents-upcoming_list')}>
          {upcomingList.map((data, index) => {
            return (
              <div
                key={index}
                className={cn('artfair_contents-upcoming_list-item')}
              >
                <img
                  className={cn('artfair_contents-upcoming_list-item-img')}
                  src={data.thumbnail}
                  alt='upcoming_img'
                  draggable={false}
                  onClick={() => handleArtFair(data.id)}
                />

                <div className={cn('artfair_contents-upcoming_list-item-wrap')}>
                  <span
                    className={cn(
                      'artfair_contents-upcoming_list-item-wrap-title'
                    )}
                    onClick={() => handleArtFair(data.id)}
                  >
                    {data.title}
                  </span>

                  <span
                    className={cn(
                      'artfair_contents-upcoming_list-item-wrap-text'
                    )}
                  >
                    {data.location}
                  </span>

                  <span
                    className={cn(
                      'artfair_contents-upcoming_list-item-wrap-text'
                    )}
                  >{`${getUTCDate(String(data.date).split('|')[0])
                    .format('MM.DD')
                    .toString()} - ${getUTCDate(String(data.date).split('|')[1])
                    .format('MM.DD.YYYY')
                    .toString()}`}</span>
                </div>
              </div>
            );
          })}
        </div>

        <div className={cn('artfair_contents-line')} />

        <div className={cn('artfair_contents-title')}>Past</div>

        <div className={cn('artfair_contents-past_list')}>
          {pastList.map((data, index) => {
            return (
              <div
                key={index}
                className={cn('artfair_contents-past_list-item')}
              >
                <img
                  className={cn('artfair_contents-past_list-item-img')}
                  src={data.thumbnail}
                  alt='past_img'
                  draggable={false}
                  onClick={() => handleArtFair(data.id)}
                />

                <div className={cn('artfair_contents-past_list-item-wrap')}>
                  <span
                    className={cn('artfair_contents-past_list-item-wrap-title')}
                    onClick={() => handleArtFair(data.id)}
                  >
                    {data.title}
                  </span>

                  {/* <span
                    className={cn(
                      'artfair_contents-past_list-item-wrap-subtitle'
                    )}
                  >
                    ARTISTS
                  </span> */}

                  <div
                    className={cn(
                      'artfair_contents-past_list-item-wrap-artists'
                    )}
                  >
                    {data.artists.map((artist: any, index: number) => {
                      if (data.artists.length === index + 1) {
                        return <Fragment key={index}>{artist.name}</Fragment>;
                      }

                      return <Fragment key={index}>{artist.name}, </Fragment>;
                    })}
                  </div>

                  <div
                    className={cn(
                      'artfair_contents-past_list-item-wrap-icon_wrap'
                    )}
                  >
                    <img
                      className={cn(
                        'artfair_contents-past_list-item-wrap-icon_wrap-icon'
                      )}
                      src={ICON_1}
                      alt='icon'
                      style={{ width: '12px', height: 'auto' }}
                      draggable={false}
                    />

                    <span
                      className={cn(
                        'artfair_contents-past_list-item-wrap-icon_wrap-text'
                      )}
                    >
                      {data.location}
                    </span>
                  </div>

                  <span
                    className={cn(
                      'artfair_contents-past_list-item-wrap-date_wrap'
                    )}
                  >
                    {/* <span
                      className={cn(
                        'artfair_contents-past_list-item-wrap-date_wrap-title'
                      )}
                    >
                      DATE
                    </span> */}

                    <span
                      className={cn(
                        'artfair_contents-past_list-item-wrap-date_wrap-date'
                      )}
                    >{`${getUTCDate(String(data.date).split('|')[0])
                      .format('MM.DD.YYYY')
                      .toString()} - ${getUTCDate(
                      String(data.date).split('|')[1]
                    )
                      .format('MM.DD.YYYY')
                      .toString()}`}</span>
                  </span>

                  <span
                    className={cn('artfair_contents-past_list-item-wrap-text')}
                    onClick={() => handleArtFair(data.id)}
                  >
                    Read More
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;

  .artfair_contents {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    &-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 30px;
      padding: 0 20px;
    }

    &-line {
      width: 100%;
      height: 1px;
      background-color: #ddd;
      margin: 30px 0;
    }

    &-current_list {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      padding: 0 20px;

      &-empty {
        width: fit-content;
        height: fit-content;
        padding: 20px;
        margin: auto;
        font-weight: 400;
        letter-spacing: 1px;
      }

      &-item {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        &-img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          object-position: center;
          margin-bottom: 20px;
          border-radius: 4px;
        }

        &-title {
          width: fit-content;
          height: fit-content;
          font-size: 18px;
          font-weight: 500;
          margin-bottom: 20px;
          user-select: none;
        }

        &-wrap {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: column;
        }

        &-icon_wrap {
          width: fit-content;
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;

          &-icon {
            margin-right: 10px;
          }

          &-text {
            font-weight: 400;
          }
        }
      }
    }

    &-upcoming_list {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-item {
        width: 100%;
        height: fit-content;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;

        &-img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          object-position: center;
        }

        &-wrap {
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 30px 20px;

          &-title {
            width: fit-content;
            height: fit-content;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
            user-select: none;
          }

          &-text {
            text-align: center;
            font-weight: 400;
            line-height: 1.5;
          }
        }
      }
    }

    &-past_list {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      margin-bottom: 50px;

      &-item {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 30px;
        z-index: 0;

        &:last-child {
          margin-bottom: 0;
          border-bottom: 0;
        }

        &-img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          object-position: center;
          margin-bottom: 20px;
        }

        &-wrap {
          width: 100%;
          height: fit-content;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;

          &-title {
            width: fit-content;
            height: fit-content;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 20px;
            user-select: none;
          }

          &-subtitle {
            width: fit-content;
            height: fit-content;
            font-weight: 500;
            margin-bottom: 5px;
          }

          &-artists {
            width: 100%;
            height: fit-content;
            display: flex;
            flex-wrap: wrap;
            line-height: 1.5;
            margin-bottom: 20px;
          }

          &-icon_wrap {
            width: fit-content;
            height: fit-content;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            margin-bottom: 20px;

            &-icon {
              margin-right: 10px;
            }
          }

          &-date_wrap {
            width: fit-content;
            height: fit-content;
            display: flex;
            justify-items: flex-start;
            align-items: flex-start;
            flex-direction: row;
            margin-bottom: 30px;

            &-title {
              font-weight: 500;
              margin-right: 10px;
            }

            &-date {
              color: #898989;
              font-weight: 400;
            }
          }

          &-text {
            width: fit-content;
            height: fit-content;
            font-size: 15px;
            font-weight: 500;
            margin: auto 0 0 0;
            user-select: none;
          }
        }
      }
    }
  }
`;
