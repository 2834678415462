import styled from 'styled-components';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { usePublications } from '../../context/Publications';

export default function Publications() {
  const [list, setList] = useState<any[]>([]);

  const { handlePublications } = usePublications();

  const getPublications = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/publications/purchases`)
      .then((response) => {
        setList(response.data.data);
      });
  };

  useEffect(() => {
    getPublications();
  }, []);

  return (
    <Box>
      <span className={cn('title')}>Publications</span>

      <div className={cn('contents')}>
        {list.map((data: any, index) => {
          return (
            <div key={index} className={cn('contents-p_item')}>
              <img
                src={data.image}
                alt='publications_img'
                className={cn('contents-p_item-img')}
                draggable={false}
                onClick={() => handlePublications(data)}
              />

              <span
                className={cn('contents-p_item-title')}
                onClick={() => handlePublications(data)}
              >
                {data.title}
              </span>

              <span
                className={cn('contents-p_item-sub_title')}
                onClick={() => handlePublications(data)}
              >
                {data.sub_title}
              </span>
            </div>
          );
        })}
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  padding: 100px 150px;

  .title {
    font-family: 'Noto Serif';
    font-size: 26px;
    font-weight: 400;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 50px;
  }

  .contents {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    row-gap: 100px;
    column-gap: 200px;

    &-p_item {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-img {
        width: 100%;
        height: 300px;
        object-fit: contain;
        object-position: center;
        margin-bottom: 20px;
        cursor: pointer;
      }

      &-title {
        width: fit-content;
        height: fit-content;
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 10px;
        letter-spacing: 1px;
        cursor: pointer;
        user-select: none;
      }

      &-sub_title {
        width: fit-content;
        height: fit-content;
        font-family: 'Noto Serif';
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1px;
        color: #707070;
        cursor: pointer;
        user-select: none;
      }
    }
  }
`;
