import styled from 'styled-components';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { getUTCDate } from '../../library/date.lib';

export default function ExhibitionPast() {
  const history = useHistory();

  const [exhibtions, setExhibitions] = useState<any[]>([]);

  const getExhibition = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition/up`)
      .then(async (response) => {
        setExhibitions(response.data.data.past);
      });
  };

  useEffect(() => {
    getExhibition();
  }, []);

  return (
    <Box>
      <div className={cn('header_wrap')}>
        <span className={cn('header_wrap-title')}>Exhibition</span>

        <div className={cn('header_wrap-btn_wrap')}>
          <span
            className={cn('header_wrap-btn_wrap-btn')}
            onClick={() => history.push('/exhibition/onview')}
          >
            On View
          </span>

          <span
            className={cn('header_wrap-btn_wrap-btn')}
            onClick={() => history.push('/exhibition-upcoming')}
          >
            Upcoming
          </span>

          <span
            className={cn('header_wrap-btn_wrap-btn')}
            onClick={() => history.push('/exhibition-past')}
          >
            Past
          </span>
        </div>
      </div>

      <div className={cn('list')}>
        {exhibtions.map((exhibition, index) => {
          return (
            <div key={index} className={cn('list-item')}>
              <img
                src={exhibition.thumbnail}
                alt='exhibition_img'
                className={cn('list-item-img')}
                draggable={false}
                onClick={() => history.push(`/exhibition/${exhibition.id}`)}
              />

              <span
                className={cn('list-item-title')}
                onClick={() => history.push(`/exhibition/${exhibition.id}`)}
              >
                {exhibition.title}
              </span>

              <span className={cn('list-item-date')}>{`${getUTCDate(
                String(exhibition.date).split('|')[0]
              )
                .format('MMM DD YYYY')
                .toString()} - ${getUTCDate(
                String(exhibition.date).split('|')[1]
              )
                .format('MMM DD YYYY')
                .toString()}`}</span>

              <span className={cn('list-item-location')}>
                {exhibition.location}
              </span>
            </div>
          );
        })}
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  padding: 100px 150px;

  .header_wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding-bottom: 20px;
    margin-bottom: 50px;
    border-bottom: 1px solid #ddd;

    &-title {
      font-family: 'Noto Serif';
      font-size: 26px;
      font-weight: 400;
    }

    &-btn_wrap {
      width: fit-content;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      &-btn {
        width: fit-content;
        height: fit-content;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        user-select: none;
        margin-right: 50px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .list {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    row-gap: 50px;
    column-gap: 50px;

    &-item {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-img {
        width: 100%;
        height: 500px;
        object-fit: cover;
        object-position: center;
        cursor: pointer;
        margin-bottom: 30px;
      }

      &-title {
        width: fit-content;
        height: fit-content;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1px;
        margin-bottom: 20px;
        cursor: pointer;
        user-select: none;
      }

      &-date {
        width: fit-content;
        height: fit-content;
        color: #828282;
        letter-spacing: 1px;
        margin-bottom: 5px;
      }

      &-location {
        width: fit-content;
        height: fit-content;
        color: #828282;
        letter-spacing: 1px;
      }
    }
  }
`;
