import styled from 'styled-components';
import Color from 'color';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import ICON_1 from '../../static/images/mail.svg';

import ICON_2 from '../../static/images/left.svg';
import ICON_3 from '../../static/images/right.svg';
import { getUTCDate } from '../../library/date.lib';
import { useToasts } from 'react-toast-notifications';

const delay = 5000;

function Main() {
  const history = useHistory();
  const { addToast } = useToasts();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [bannerList, setBannerList] = useState<any[]>([]);
  const [exhibition, setExhibition] = useState<any>(null);
  const [artFairList, setArtFairList] = useState<any[]>([]);

  const [bannerIndex, setBannerIndex] = useState(0);
  const timeoutRef = useRef<any>(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const getList = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/news/banner/on`)
      .then((response) => {
        setBannerList(response.data.data);
      });

    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/exhibition/onview`)
      .then(async (response) => {
        setExhibition(response.data.data);
      });

    axios
      .get(`${process.env.REACT_APP_SERVER_ADDRESS}/art/artfair-main`)
      .then((response) => {
        setArtFairList(response.data.data);
      });
  };

  const postMail = () => {
    if (email === '' || firstName === '' || lastName === '') {
      addToast('Please enter your information.', { appearance: 'warning' });

      return;
    }

    axios
      .post(`${process.env.REACT_APP_SERVER_ADDRESS}/contact/mail`, {
        email,
        first_name: firstName,
        last_name: lastName,
      })
      .then(() => {
        addToast('Thank you for subscribing to our mailing service.', {
          appearance: 'success',
        });
        setEmail('');
        setFirstName('');
        setLastName('');
      });
  };

  useEffect(() => {
    if (bannerList.length >= 2) {
      resetTimeout();

      timeoutRef.current = setTimeout(() => {
        if (bannerIndex >= bannerList.length - 1) {
          setBannerIndex(0);

          return;
        }

        setBannerIndex(bannerIndex + 1);
      }, delay);
    }

    return () => {
      resetTimeout();
    };
  }, [bannerIndex]);

  useEffect(() => {
    if (bannerList.length >= 2) {
      resetTimeout();

      timeoutRef.current = setTimeout(() => {
        if (bannerIndex >= bannerList.length - 1) {
          setBannerIndex(0);

          return;
        }

        setBannerIndex(bannerIndex + 1);
      }, delay);
    }

    return () => {
      resetTimeout();
    };
  }, [bannerList]);

  useEffect(() => {
    getList();
  }, []);

  return (
    <Block>
      {bannerList.length ? (
        <div className={cn('banner')}>
          <img
            className={cn('banner-img')}
            src={bannerList[bannerIndex].image}
            alt='banner_img'
            draggable={false}
          />

          <div className={cn('banner-wrap')}>
            <div className={cn('banner-wrap-text_wrap')}>
              <span className={cn('banner-wrap-text_wrap-title')}>
                {bannerList[bannerIndex].title}
              </span>

              <span className={cn('banner-wrap-text_wrap-subtitle')}>
                {bannerList[bannerIndex].subtitle}
              </span>

              <span className={cn('banner-wrap-text_wrap-text')}>
                {bannerList[bannerIndex].text}
              </span>

              {bannerList[bannerIndex].subtext ? (
                <span className={cn('banner-wrap-text_wrap-text2')}>
                  {bannerList[bannerIndex].subtext}
                </span>
              ) : (
                <></>
              )}
            </div>

            <div className={cn('banner-wrap-icon_wrap')}>
              <img
                src={ICON_2}
                alt='icon'
                className={cn('banner-wrap-icon_wrap-icon')}
                draggable={false}
                onClick={() => {
                  if (bannerIndex === 0) return;

                  setBannerIndex(bannerIndex - 1);
                }}
              />

              <span className={cn('banner-wrap-icon_wrap-text')}>
                {bannerIndex + 1}/{bannerList.length}
              </span>

              <img
                src={ICON_3}
                alt='icon'
                className={cn('banner-wrap-icon_wrap-icon')}
                draggable={false}
                onClick={() => {
                  if (bannerIndex === bannerList.length - 1) return;

                  setBannerIndex(bannerIndex + 1);
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {exhibition ? (
        <div className={cn('wrap_exhibition')}>
          <div className={cn('wrap_exhibition-title_wrap')}>
            <span className={cn('wrap_exhibition-title_wrap-title')}>
              Gallery Exhibition
            </span>

            <div className={cn('wrap_exhibition-title_wrap-line')} />
          </div>

          <div className={cn('wrap_exhibition-contents')}>
            <img
              src={exhibition.thumbnail}
              alt='exhibition_img'
              className={cn('wrap_exhibition-contents-img')}
              draggable={false}
            />

            <div className={cn('wrap_exhibition-contents-wrap')}>
              <span className={cn('wrap_exhibition-contents-wrap-title')}>
                {exhibition.title}
              </span>

              <span className={cn('wrap_exhibition-contents-wrap-date')}>
                {`${getUTCDate(String(exhibition.date).split('|')[0])
                  .format('MMM DD YYYY')
                  .toString()} - ${getUTCDate(
                  String(exhibition.date).split('|')[1]
                )
                  .format('MMM DD YYYY')
                  .toString()}`}
              </span>

              <span className={cn('wrap_exhibition-contents-wrap-location')}>
                {exhibition.location}
              </span>

              <span
                className={cn('wrap_exhibition-contents-wrap-visit')}
                onClick={() => history.push('/exhibition/onview')}
              >
                Visit Exhibition
              </span>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {artFairList.length ? (
        <div className={cn('wrap_artfair')}>
          <div className={cn('wrap_artfair-title_wrap')}>
            <span className={cn('wrap_artfair-title_wrap-title')}>
              Art Fair
            </span>

            <div className={cn('wrap_artfair-title_wrap-line')} />
          </div>

          <div className={cn('wrap_artfair-contents_wrap')}>
            {artFairList.map((artfair, index) => {
              if (index > 3) return <></>;

              return (
                <div
                  key={index}
                  className={cn('wrap_artfair-contents_wrap-item')}
                >
                  <img
                    className={cn('wrap_artfair-contents_wrap-item-img')}
                    src={artfair.thumbnail}
                    alt='artfair_img'
                    draggable={false}
                    onClick={() => history.push(`/artfair/${artfair.id}`)}
                  />

                  <span
                    className={cn('wrap_artfair-contents_wrap-item-title')}
                    onClick={() => history.push(`/artfair/${artfair.id}`)}
                  >
                    {artfair.title}
                  </span>

                  {artfair.artists.map((artist: any, index: number) => {
                    return (
                      <span
                        key={index}
                        className={cn('wrap_artfair-contents_wrap-item-artist')}
                      >
                        {artist.name}
                      </span>
                    );
                  })}

                  <span
                    className={cn('wrap_artfair-contents_wrap-item-continue')}
                    onClick={() => history.push(`/artfair/${artfair.id}`)}
                  >
                    Continue reading
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={cn('wrap_email')}>
        <div className={cn('wrap_email-text')}>
          Sign up for updates
          <br />
          Join our mailing list for updates about our artists, exhibitions,
          <br />
          events, and more.
        </div>

        <div className={cn('wrap_email-wrap')}>
          <div className={cn('wrap_email-wrap-inputs')}>
            <input
              className={cn('wrap_email-wrap-inputs-input')}
              placeholder='Enter Your First Name'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />

            <input
              className={cn('wrap_email-wrap-inputs-input')}
              placeholder='Enter Your Last Name'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <input
            className={cn('wrap_email-wrap-input')}
            placeholder='Enter Your E-mail Address'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className={cn('wrap_email-wrap-btn')} onClick={postMail}>
            <img
              className={cn('wrap_email-wrap-btn-icon')}
              src={ICON_1}
              alt='mail_icon'
              draggable={false}
            />
          </div>
        </div>

        <div className={cn('wrap_email-text_2')}>
          We will process the personal data you have supplied in accordance with
          our privacy policy.
          <br />
          You can unsubscribe or change your preferences at any time by clicking
          the link in any emails.
        </div>
      </div>
    </Block>
  );
}

const Block = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  .banner {
    width: 100%;
    height: 100vh;
    position: relative;
    margin-bottom: 100px;

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &-wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${Color('#000').alpha(0.3).hsl().toString()};

      &-text_wrap {
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 150px;
        bottom: 250px;

        &-title {
          width: fit-content;
          height: fit-content;
          font-size: 50px;
          font-weight: 400;
          color: #fff;
          margin-bottom: 10px;
        }

        &-subtitle {
          width: fit-content;
          height: fit-content;
          font-size: 35px;
          font-style: italic;
          color: #fff;
          margin-bottom: 30px;
        }

        &-text {
          width: fit-content;
          height: fit-content;
          font-size: 24px;
          font-weight: 200;
          color: #fff;
          margin-bottom: 10px;
        }

        &-text2 {
          width: fit-content;
          height: fit-content;
          font-size: 24px;
          font-weight: 200;
          color: #fff;
        }
      }

      &-icon_wrap {
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        position: absolute;
        left: 150px;
        bottom: 150px;

        &-icon {
          width: 15px;
          height: auto;
          cursor: pointer;
        }

        &-text {
          font-size: 16px;
          color: #fff;
          letter-spacing: 2px;
          user-select: none;
          margin: 0 30px;
        }
      }
    }
  }

  .wrap_exhibition {
    width: 100%;
    max-width: 1920px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 150px;
    margin-bottom: 100px;

    &-title_wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      user-select: none;

      &-title {
        width: 180px;
        height: fit-content;
        font-size: 18px;
        font-weight: 200;
      }

      &-line {
        width: calc(100% - 180px);
        height: 1px;
        background-color: #ddd;
      }
    }

    &-contents {
      width: 100%;
      height: 600px;
      display: flex;
      flex-direction: row;
      padding: 0 50px;

      &-img {
        width: 55%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      &-wrap {
        width: 45%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 30px;

        &-title {
          font-size: 30px;
          font-weight: 500;
          margin-bottom: 50px;
          text-align: center;
        }

        &-date,
        &-location {
          font-size: 16px;
          font-weight: 200;
          letter-spacing: 1px;
          margin-bottom: 10px;
          text-align: center;
        }

        &-visit {
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 1px;
          border-bottom: 1px solid #000;
          margin-top: 40px;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }

  .wrap_artfair {
    width: 100%;
    max-width: 1920px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 150px;
    margin-bottom: 100px;

    &-title_wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      user-select: none;

      &-title {
        width: 100px;
        height: fit-content;
        font-size: 18px;
        font-weight: 200;
      }

      &-line {
        width: calc(100% - 100px);
        height: 1px;
        background-color: #ddd;
      }
    }

    &-contents_wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;

      &-item {
        width: 25%;
        height: initial;
        display: flex;
        flex-direction: column;
        padding: 30px 20px;
        border-right: 1px solid #ddd;

        &:last-child {
          border-right: 0;
        }

        &-img {
          width: 100%;
          height: 600px;
          object-fit: cover;
          object-position: center;
          margin-bottom: 20px;
          cursor: pointer;
        }

        &-title {
          font-family: 'Noto Serif';
          font-size: 18px;
          font-weight: 600;
          font-style: italic;
          margin-bottom: 30px;
          user-select: none;
          cursor: pointer;
        }

        &-artist {
          font-family: 'Noto Serif';
          font-size: 16px;
          margin-bottom: 10px;
        }

        &-continue {
          width: fit-content;
          height: fit-content;
          margin-top: 30px;
          cursor: pointer;
          user-select: none;
          font-weight: 400;
        }
      }
    }
  }

  .wrap_journal {
    width: 100%;
    max-width: 1920px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 150px;
    margin-bottom: 150px;

    &-title_wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 50px;
      user-select: none;

      &-title {
        width: 100px;
        height: fit-content;
        font-size: 18px;
        font-weight: 200;
      }

      &-line {
        width: calc(100% - 100px);
        height: 1px;
        background-color: #ddd;
      }
    }

    &-contents_wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;

      &-item {
        width: 33.3%;
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }

        &-img {
          width: 250px;
          height: 400px;
          object-fit: cover;
          object-position: center;
          margin-right: 20px;
          cursor: pointer;
        }

        &-wrap {
          width: fit-content;
          height: fit-content;
          display: flex;
          flex-direction: column;

          &-title {
            font-size: 16px;
            font-weight: 500;
            cursor: pointer;
            user-select: none;
            margin-bottom: 10px;
          }

          &-date {
            color: #898989;
            font-weight: 400;
            letter-spacing: 2px;
            cursor: pointer;
            user-select: none;
          }
        }
      }
    }
  }

  .wrap_email {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-bottom: 150px;

    &-text {
      font-size: 18px;
      font-weight: 500;
      line-height: 2;
      text-align: center;
      margin-bottom: 30px;
    }

    &-text_2 {
      color: #898989;
      font-weight: 500;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 30px;
    }

    &-wrap {
      width: 800px;
      height: fit-content;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 30px;

      &-inputs {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        margin-bottom: 20px;

        &-input {
          width: 50%;
          height: 50px;
          border: 1px solid #ddd;
          outline: none;
          padding: 0 20px;
          font-size: 16px;
          margin-right: 20px;

          &:last-child {
            margin: 0;
          }
        }
      }

      &-input {
        width: 700px;
        height: 50px;
        border: 1px solid #ddd;
        outline: none;
        padding: 0 20px;
        font-size: 16px;
      }

      &-btn {
        width: 100px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ddd;
        border-left: 0;
        cursor: pointer;

        &-icon {
          width: 30px;
          height: auto;
        }
      }
    }
  }
`;

export default Main;
